<template>
  <div v-if="!activeChat" class="contact-list">
    <div class="contact-title">Conversations</div>
    <div
      v-for="contact in contacts"
      :key="contact.id"
      class="contact"
      @click="openChat(contact)"
    >
      <img :src="contact.image" class="contact-image" />
      <span class="contact-name">{{ contact.name }}</span>
    </div>
  </div>

  <div v-else class="chat-window">
    <div class="chat-header">
      <div class="previous-button" @click="goBack"><</div>
      {{ activeChat.name }}
    </div>
    <div class="chat-bubbles">
      <div
        v-for="message in activeChat.messages"
        :key="message.id"
        class="chat-bubble"
        :class="{ 'from-me': message.fromMe }"
      >
        {{ message.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contacts: [
        {
          id: 1,
          name: "Brenda",
          image: require("../../static/img/Photos/Brenda/1.png"),
          messages: [
            {
              id: 1,
              text: "Hello Arno, je voulais te souhaiter un merveilleux anniversaire !! Je te souhaite que du bonheur bisous",
              fromMe: false,
            },
            // { id: 2, text: "Oui ça va et toi ?", fromMe: true },
          ],
        },
        {
          id: 2,
          name: "Ben",
          image: require("../../static/img/Photos/Ben/1.png"),
          messages: [
            { id: 1, text: "Yo Arno, je te souhaite le best des anniversaires, joyeux 30 ans !!!", fromMe: false },
            // { id: 2, text: "Pas grand chose", fromMe: true },
          ],
        },
      ],
      activeChat: null,
    };
  },
  methods: {
    openChat(contact) {
      this.activeChat = contact;
    },
    goBack() {
      this.activeChat = null;
    },
  },
};
</script>

<style scoped>
.contact-list {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 5px;

  position: absolute;
  left: calc(50% - 40vw);
  top: calc(50% - 25vh);
  width: 80vw;
  height: 50vh;
}

.contact-title {
  font-size: 20px;
  text-align: center;
  font-family: "BlankPlace";
  padding: 10px 0;
  background: #3d567a;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.contact {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: grey solid 1px;
}

.contact img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.contact-name {
  font-size: 20px;
}

/* !!!! */

.chat-window {
  display: flex;
  flex-direction: column;
  background: white;

  position: absolute;
  left: calc(50% - 40vw);
  top: calc(50% - 25vh);
  width: 80vw;
  height: 50vh;
}

.chat-header {
  position: relative;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  background: #3d567a;
  color: white;
  font-family: "BlankPlace";
}

.chat-bubbles {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
}

.chat-bubble {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #e0e0e0;
  max-width: 70%;
  text-align: left;
}

.from-me {
  background-color: #add8e6;
  margin-left: auto;
}

.previous-button {
  position: absolute;
  left: 19px;
  top: 3px;
  font-size: 29px;
}
</style>
