<template>
  <div class="home">
    <Header />
    <Cards v-if="showCards" class="animate__animated animate__fadeIn" />
    <ContactList v-else class="animate__animated animate__fadeIn" />
    <SideViewer />
    <PostSwipe />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Cards from "../components/Cards.vue";
import SideViewer from "../components/SideViewer.vue";
import PostSwipe from "../components/PostSwipe.vue";
import ContactList from "../components/ContactList.vue";
import bus from "../../tools/bus/bus";

export default {
  data() {
    return {
      showCards: true,
    };
  },
  created() {
    bus.$on("openMessages", this.openMessages);
  },
  methods: {
    openMessages() {
      this.showCards = !this.showCards;
    },
  },
  components: {
    Header,
    Cards,
    SideViewer,
    PostSwipe,
    ContactList,
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;

  vertical-align: center;
  align-content: center;
  justify-self: center;
  align-items: center;
  text-align: center;
}
</style>