<template>
  <div class="header">
    <div class="bubble-icon-container">
      <img src="../../static/img/Message.png" />
      <div class="message-count" :class="messageCount > 0 ? 'message-unread' : ''" @click="clickMessages">{{ messageCount }}</div>
    </div>
  </div>
</template>

<script>
import bus from '../../tools/bus/bus';

export default {
  data() {
    return {
      messageCount: 0,
    };
  },
  created() {
    bus.$on("match", () => {
      this.messageCount++
    })
  },
  methods: {
    clickMessages() {
      bus.$emit("openMessages")
      this.messageCount = 0
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: flex-end; 
  margin-right: 15px; 
  margin-top: 15px; 
  width: 100%;
}

.bubble-icon-container {
  position: relative; 
}

.bubble-icon-container img {
  width: 60px;
}

.message-count {
  position: absolute;
  top: 28px;
  right: 31px;
  /* background-color: red; */
  color: #3d567a;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 30px;
  transform: translate(50%, -50%);
  font-family: "BlankPlace";
}

.message-unread {
  color: rgba(247, 28, 28) !important;
}
</style>
