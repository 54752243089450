const UPDATE_DRAG = 'UPDATE_DRAG'

const state = {
  dragMinOffset: 70,
  dragValue: 0,
}

const actions = {
  updateDrag ({ commit }, val) {
    commit(UPDATE_DRAG, val)
  },
}

const mutations = {
  [UPDATE_DRAG] (state, val) {
    state.dragValue = val
  },
}

export default {
  state,
  actions,
  mutations
}