<template>
  <div class="fullscreen-container" v-show="showGif">
    <img :src="gifSrc" class="center-gif" />
  </div>
</template>

<script>
import bus from '../../tools/bus/bus';

export default {
  data() {
    return {
      showGif: false,
      gifSrc: null, // Track the GIF source
    };
  },
  mounted() {
    bus.$on("match", () => {
      this.resetGif(true);
      this.showGif = true;
      setTimeout(() => {
        this.showGif = false;
      }, 2500);
    });
    bus.$on("notMatch", () => {
      this.resetGif(false);
      this.showGif = true;
      setTimeout(() => {
        this.showGif = false;
      }, 2700);
    });
  },
  methods: {
    resetGif(isMatch) {
      this.gifSrc = null;
      this.$nextTick(() => {
        this.gifSrc = require(`../../static/img/Gif/${isMatch ? '' : 'Not'}Match.gif`);
      });
    },
  },
  beforeDestroy() {
    bus.$off("match");
  },
};
</script>

<style>
.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  pointer-events: none;
}

.center-gif {
  max-width: 80%;
  max-height: 80%;
}
</style>
