<template>
  <div>
    <vue-swing
      @dragmove="onDragMove"
      @dragend="onDragEnd"
      :config="config"
      ref="vueswing"
    >
      <div
        v-for="card in currentCards"
        :key="card.id"
        class="card"
        :ref="`card_` + card.id"
      >
        <div
          class="card-title center-vertical"
          :style="'background: ' + card.backgroundTitle"
        >
          {{ card.title }}
        </div>
        <div
          class="card-image"
          @mousedown="startOverlay"
          @mouseup="handleClick"
          @mouseleave="resetOverlay"
        >
          <div class="overlay" :class="overlayClass"></div>
          <div class="arrow-left"><</div>
          <div class="arrow-right">></div>
          <img :src="card.images.length >= currentImageIndex + 1 ? card.images[currentImageIndex] : card.images[0]" />
        </div>
        <div class="card-buttons center-vertical">
          <div class="card-button">
            <img src="../../static/img/Cross.png" />
          </div>
          <div class="card-separator">|</div>
          <div class="card-button">
            <img src="../../static/img/Heart.png" />
          </div>
        </div>
      </div>
    </vue-swing>
  </div>
</template>

<script>
import VueSwing from "vue-swing";
import bus from "../../tools/bus/bus";

export default {
  data() {
    return {
      config: {
        allowedDirections: [],
        minThrowOutDistance: 150,
        maxThrowOutDistance: 300,
      },
      cards: [
        {
          id: 1,
          backgroundTitle: "black",
          images: [require("../../static/img/Photos/Brenda/1.png")],
          title: "Brenda",
          description:
            "Oui bonjour je m'appelle Brenda mais vous pouvez m'appeler Brendouille, Bren' ou bien encore Br'. Arno pour moi, c'est l'incarnation de la beauté divine et du charme triphasique.",
        },
        {
          id: 2,
          backgroundTitle: "#df8854",
          images: [require("../../static/img/Photos/Aminn/1.png")],
          title: "Aminn",
          description:
            "Arno pour moi en quatre mots c'est : drôle, généreux, don et ubiquité. Il a toujours un sourire aux lèvres, et il est toujours le premier à vivre : que ce soit pour la déconne, ou pour avoir des conversations éternellement existentielles sur tous les sujets qui existent. Bon anniversaire mon pote <3",
        },
        {
          id: 3,
          backgroundTitle: "pink",
          images: [require("../../static/img/Photos/Brenda/1.png")],
          title: "Brenda",
          description:
            "Oui bonjour je m'appelle Brenda mais vous pouvez m'appeler Brendouille, Bren' ou bien encore Br'. Arno pour moi, c'est l'incarnation de la beauté divine et du charme triphasique.",
        },
        {
          id: 4,
          backgroundTitle: "#df8854",
          images: [
            require("../../static/img/Photos/Ben/1.png"),
            require("../../static/img/Photos/Ben/2.png"),
          ],
          title: "Ben",
          description:
            "Arno pour moi en quatre mots c'est : drôle, généreux, don et ubiquité. Il a toujours un sourire aux lèvres, et il est toujours le premier à vivre : que ce soit pour la déconne, ou pour avoir des conversations éternellement existentielles sur tous les sujets qui existent. Bon anniversaire mon pote <3",
        },
      ],
      thrownCardsIds: [],
      dragValue: 0,
      overlayClass: "", 
      clickStartTime: 0,
      currentImageIndex: 0,
    };
  },
  computed: {
    dragMinOffset() {
      return this.$store.state.cards.dragMinOffset;
    },
    nbThrownCards() {
      return this.thrownCardsIds.length;
    },
    currentCard() {
      return this.cards[this.cards.length - 1 - this.nbThrownCards];
    },
    currentCards() {
      return this.cards.filter((c) => !this.thrownCardsIds.includes(c.id));
    },
    isDraggingRight() {
      return this.dragValue < 0;
    },
    isDraggingLeft() {
      return this.dragValue > 0;
    },
  },
  methods: {
    onDragMove(event) {
      const offset = event.offset; // Get the horizontal offset of the drag
      this.dragValue = offset;
      this.$store.dispatch("updateDrag", this.dragValue);
    },
    onDragEnd() {
      console.log("On drag end", this.dragValue);
      if (Math.abs(this.dragValue) > this.dragMinOffset) this.throwOutCurrent();
      else {
        this.dragValue = 0;
        this.$store.dispatch("updateDrag", this.dragValue);
      }
    },
    throwOutCurrent() {
      const id = this.cards[this.cards.length - 1 - this.nbThrownCards].id;
      if (this.thrownCardsIds.includes(id)) return;
      const card =
        this.$refs["card_" + (this.cards.length - this.nbThrownCards)][0];
      card.classList.add(
        `thrown-out-${this.isDraggingLeft ? "left" : "right"}`
      );
      console.log("swipe");
      bus.$emit(this.isDraggingLeft ? "match" : "notMatch");
      this.dragValue = 0;
      this.$store.dispatch("updateDrag", this.dragValue);
      setTimeout(() => {
        this.thrownCardsIds.push(id);
      }, 250);
    },
    startOverlay(event) {
      this.clickStartTime = Date.now();
      const isLeft = event.offsetX < event.target.clientWidth / 2;
      this.overlayClass = isLeft ? "left" : "right"; // Overlay on left or right
    },
    handleClick(event) {
      const clickDuration = Date.now() - this.clickStartTime;
      if (clickDuration < 2000) {
        // Only register if held for less than 2 seconds
        const isLeft = event.offsetX < event.target.clientWidth / 2;
        if (isLeft) {
          this.prevImage(); // Go to the previous image
        } else {
          this.nextImage(); // Go to the next image
        }
      }
      this.resetOverlay(); // Reset overlay
    },
    resetOverlay() {
      this.overlayClass = ""; // Remove overlay
    },
    prevImage() {
      this.currentImageIndex--
      // Logic to go to the previous image
    },
    nextImage() {
      this.currentImageIndex++
      // Logic to go to the next image
    },
  },
  components: { VueSwing },
};
</script>

<style>
.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start of the column */
  text-align: center;

  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  position: absolute;
  left: calc(50% - 150px);
  top: calc(50% - 215px);

  height: 430px;
  width: 300px;
}

.card-title {
  height: 24px;
  padding: 10px;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 800;
  background: #df8854;
  color: white;
}

.card-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  flex-shrink: 0;
}

.card-buttons {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-evenly;
}

.card-button img {
  width: 30px;
}

.card.thrown-out-left {
  transition: opacity 0.25s ease-out, left 0.25s ease;
  opacity: 0;
  left: 90%;
}

.card.thrown-out-right {
  transition: opacity 0.25s ease-out, left 0.25s ease;
  opacity: 0;
  left: -110%;
}

/*  */
.card-image {
  position: relative;
  display: inline-block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  pointer-events: none; /* Make overlay non-interactive */
  transition: opacity 0.2s ease;
  opacity: 0;
}

.overlay.left {
  left: 0;
  opacity: 1;
}

.overlay.right {
  left: 50%;
  opacity: 1;
}

.card-image img {
  display: block;
  width: 100%;
  height: auto;
}

.arrow-left {
  position: absolute;
  left: 10px;
  bottom: 10%;
  font-size: 30px;
  font-family: "BlankPlace";
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

.arrow-right {
  position: absolute;
  right: 10px;
  bottom: 10%;
  font-size: 30px;
  font-family: "BlankPlace";
  color: white;
  text-shadow: 2px 2px 4px #000000;
}
</style>
