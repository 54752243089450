<template>
  <div class="split-container">
    <div class="half left" :class="showLeft ? 'show' : 'hide'">
      <img src="../../static/img/Cross.png" width="70px" style="filter: brightness(0) invert(1)"/>
    </div>
    <div class="half right" :class="showRight ? 'show' : 'hide'">
      <img src="../../static/img/Heart.png" width="70px" style="filter: brightness(0) invert(1)"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBoth: false, // Show both halves
    };
  },
  computed: {
    dragMinOffset() {
      return this.$store.state.cards.dragMinOffset;
    },
    dragValue() {
      return this.$store.state.cards.dragValue;
    },
    showLeft() {
      return this.dragValue < -this.dragMinOffset;
    },
    showRight() {
      return this.dragValue > this.dragMinOffset;
    },
  },
};
</script>

<style>
.split-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none; /* Make the container ignore pointer events */
}

.half {
  position: absolute;
  width: 50%;
  height: 100%;
  display: block;
  pointer-events: none; /* Make the halves ignore pointer events */
  text-align: center;
  font-size: 2rem;
  line-height: 100vh; /* Vertically center text */
  color: white;
  user-select: none; /* Prevent text selection */
  transition: opacity ease 0.25s;

  font-family: "BlankPlace";
  text-shadow: 0px 0px 10px black;
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

.left {
  left: 0;
  background-color: rgba(247, 28, 28, 0.425);
}

.right {
  right: 0;
  background-color: rgba(54, 236, 54, 0.425);
}
</style>
